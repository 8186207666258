import axios from "axios";
import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useContext, useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { userContext } from "../../context/userContext";
import { auth, db } from "../../firebase/firebase";
import { OutlinedBox } from "../../styles";

const Forex = ({ action, user, open }) => {
  const { fixTrade, setFixTrade } = open;

  const { accounts } = useContext(userContext);
  const pairs = [
    { name: "EUR/USD", symbol: "EUR/USD" },
    { name: "GBP/USD", symbol: "GBP/USD" },
    { name: "USD/CHF", symbol: "USD/CHF" },
    { name: "USD/CAD", symbol: "USD/CAD" },
    { name: "NZD/USD", symbol: "NZD/USD" },
    { name: "USD/JPY", symbol: "USD/JPY" },
    { name: "AUD/USD", symbol: "AUD/USD" },
    { name: "USD/GBP", symbol: "USD/GBP" },
    { name: "EUR/CAD", symbol: "EUR/CAD" },
    { name: "EUR/AUD", symbol: "EUR/AUD" },
    { name: "EUR/JPY", symbol: "EUR/JPY" },
    { name: "EUR/CHF", symbol: "EUR/CHF" },
    { name: "EUR/GBP", symbol: "EUR/GBP" },
    { name: "AUD/CAD", symbol: "AUD/CAD" },
    { name: "GBP/CHF", symbol: "GBP/CHF" },
    { name: "GBP/JPY", symbol: "GBP/JPY" },
    { name: "CHF/JPY", symbol: "CHF/JPY" },
    { name: "AUD/JPY", symbol: "AUD/JPY" },
    { name: "AUD/NZD", symbol: "AUD/NZD" },
  ];
  const [pair, setPair] = useState(pairs[0].symbol);
  const [fiatAccount, setFiatAccount] = useState([]);
  const [balance, setBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [marginError, setMarginError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pairPrice, setPairPrice] = useState("");
  const [quote, setQuote] = useState("");
  const [base, setBase] = useState("");
  const [USDBase, setUSDBase] = useState("");
  const [tp, setTP] = useState("");
  const [sl, setSL] = useState("");
  const [tpBuyError, setTPBuyError] = useState(false);
  const [tpSellError, setTPSellError] = useState(false);
  const [slBuyError, setSLBuyError] = useState(false);
  const [slSellError, setSLSellError] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const { currentAccount } = useContext(userContext);
  const [liveAccount, setLiveAccount] = useState([]);
  const [practiceAccount, setPracticeAccount] = useState([]);

  const [profitType, setProfitType] = useState("profit");

  const [id, setID] = useState("");
  const amountRef = useRef();
  const tpRef = useRef();
  const slRef = useRef();
  const [time, setTime] = useState(2);
  const [margin, setMargin] = useState("");

  const [extraOptions, setExtraOptions] = useState(false);
  const [profit, setProfit] = useState("");

  // const entryRef = useRef();
  const profitRef = useRef();

  const [entry, setEntry] = useState();

  // For JPY Quote
  // Rest

  // For JPY Quotes, pip value = 0.01 * Size(Amount * 1000) / Current Price.toFixed(2)
  // For Others, pip value = 0.0001 * Size(Amount * 1000) / Current Price.toFixed(4)

  // Pair to USD

  async function fetchForexPrice(pair) {
    const pairsSplit = pair.split("/");
    const Base = pairsSplit[0];
    const Quote = pairsSplit[1];
    setBase(Base);
    setQuote(Quote);
    const url = `https://api.fastforex.io/fetch-one?from=${Base}&to=${Quote}&api_key=fddcbcbb7c-5c1d6d4099-rakaq9`;

    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { result } = response.data;
        setPairPrice(
          Quote === "JPY"
            ? Number(...Object.values(result)).toFixed(2)
            : Number(...Object.values(result)).toFixed(4)
        );

        setEntry(pairPrice);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    async function baseToUSD(curr) {
      const url = `https://api.fastforex.io/fetch-one?from=${curr}&to=USD&api_key=fddcbcbb7c-5c1d6d4099-rakaq9`;

      const config = {
        method: "get",
      };

      try {
        const response = await axios.get(url, config);
        if (response) {
          const { result } = response.data;
          setUSDBase(...Object.values(result));
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    if (base) {
      baseToUSD(base);
    }
  }, [base]);

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      if (live && practice) {
        setLiveAccount(live);
        setPracticeAccount(practice);
        fetchForexPrice(pair);
      }
    } else {
      return;
    }
  }, [accounts]);

  useEffect(() => {
    if (user) {
      setID(user.uid);
    }
  }, [user]);

  useEffect(() => {
    if (currentAccount && liveAccount && practiceAccount) {
      switch (currentAccount) {
        case "live":
          setFiatAccount(liveAccount.Fiat);
          break;
        case "practice":
          setFiatAccount(practiceAccount.Fiat);
          break;
        default:
          break;
      }
    }
  }, [currentAccount, liveAccount, practiceAccount]);

  useEffect(() => {
    if (fiatAccount) {
      setBalance(fiatAccount.value);
    }
  }, [fiatAccount]);

  function handlePairChange(e) {
    const value = e.target.value;
    setPair(value);
    reset();
  }

  useEffect(() => {
    if (pair) {
      fetchForexPrice(pair);
    }
  }, [pair]);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
  }

  function handleProfit(e) {
    const value = e.target.value;
    if (value > 0) {
      setProfit(value);
    } else {
      setProfit("");
    }
  }

  function handleTimeInForce(e) {
    const value = e.target.value;
    // const timeSlice = value.slice(0, value.indexOf(" "));
    setTime(Number(value));
  }

  function reset() {
    setAmount("");
    setAmountError(false);
    setTPBuyError(false);
    setTPSellError(false);
    setSLBuyError(false);
    setSLSellError(false);
    setMargin("");

    amountRef.current.value = "";

    // entryRef.current.value = "";
  }

  useEffect(() => {
    setMargin(
      Number(((1000 * Number(amount)) / 30) * Number(pairPrice)).toFixed(2)
    );
  }, [amount, pairPrice]);

  useEffect(() => {
    if (margin > balance) {
      setMarginError(true);
    } else {
      setMarginError(false);
    }
  }, [margin, balance]);

  const handleTrade = () => {
    const tradeAction = action.slice(0, 1).toUpperCase() + action.slice(1);

    setIsSubmitting(true);

    const pairsSplit = pair.split("/");
    const Base = pairsSplit[0];
    const Quote = pairsSplit[1];

    const valuePerPip =
      Quote === "JPY"
        ? (
            ((0.01 * (Number(amount) * 1000)) / Number(pairPrice).toFixed(2)) *
            Number(USDBase).toFixed(2)
          ).toFixed(2)
        : (
            ((0.0001 * (Number(amount) * 1000)) /
              Number(pairPrice).toFixed(4)) *
            Number(USDBase)
          ).toFixed(2);

    const details = {
      advanced: isAdvanced ? true : false,
      margin,
      action: action === "up" ? "Buy" : "Sell",
      account: "live",
      profitType,
      tp,
      fixed: true,
      profit: profit ? profit : 0,
      sl,
      entry: pairPrice,
      pair,
      time,
      perpip: valuePerPip,
      amount,
    };

    submitTrade(details);
  };

  //submit Crypto Order Placement
  async function submitTrade(details) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      user.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "open",
      fixed: true,
      profit: profit ? profit : 0,
      type: "Forex",
      profitType,
      user,
      amount,
      asset: pair,
      price: entry,
      action: action === "up" ? "Buy" : "Sell",
      margin,
      details,
    })
      .then(() => {
        postTrade(user, str, details);
      })
      .catch((error) => {
        toast.error("Order Could Not Complete.");
      });
  }

  async function postTrade(user, str, details) {
    const url = "https://fin-http-production.up.railway.app/avefinance/forex";

    const base = {
      user,
      ref: str,
      details,
    };

    const config = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          setFixTrade(false);
          setIsSubmitting(false);
          toast.success("Trade sucessfully placed.");
          // reset();
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Trade Could Not Complete.");
        setIsSubmitting(false);
      });
  }

  function handleTypeChange(e) {
    const { value } = e.target;
    setProfit(true);
    setProfitType(value);
  }

  return (
    <Wrapper>
      <div className="selectWrap">
        <label htmlFor="pair">Asset</label>
        <div className="selectBox">
          <div className="selectTop">
            <select name="asset" onChange={handlePairChange}>
              {pairs.map((par) => (
                <option key={par.symbol}>{par.symbol}</option>
              ))}
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>

      {/* <div
        className="clickable"
        style={{
          display: "flex",
          placeSelf: "end",
          alignItems: "center",
          gap: "0.3rem",
        }}
      >
        <input
          type="checkbox"
          style={{ accentColor: "#1199fa" }}
          onChange={() => setExtraOptions(!extraOptions)}
        />
        Extra
      </div> */}

      <div className="selectWrap">
        <label htmlFor="pair">Type</label>
        <div className="selectBox">
          <div className="selectTop">
            <select name="asset" onChange={handleTypeChange}>
              <option value="profits">Profit</option>
              <option value="loss">Loss</option>
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>

      <div className="amWrap">
        <label htmlFor="amount">Amount </label>
        <div className="amBx">
          <div className="amTop">
            <input
              ref={amountRef}
              placeholder="1000"
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleAmount}
            />
          </div>
        </div>
      </div>

      {/* <div className="amWrap">
        <label htmlFor="amount">Profit</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder="1000"
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleProfit}
              ref={profitRef}
            />
          </div>
          
        </div>
      </div>  */}

      <div className="selectWrap">
        <label htmlFor="timeinforce">Duration(minutes)</label>
        <div className="selectBox">
          <div className="selectTop">
            <select name="timeinforce" onChange={handleTimeInForce}>
              <option>2</option>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>30</option>
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>

      <button
        disabled={!amount || !pair || !pairPrice || !time}
        className={
          !amount || !pair || !pairPrice || !time
            ? "button disabled"
            : "button submit"
        }
        onClick={handleTrade}
      >
        {isSubmitting ? (
          <img
            src="/svg-loaders/tail-spin.svg"
            alt="loading"
            width={24}
            height={24}
          />
        ) : (
          <span className="capitalize" style={{ alignSelf: "center" }}>
            {"Place " + action + " Order"}
          </span>
        )}
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 0.8rem;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }


  .sel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  } 
`;

export default Forex;
