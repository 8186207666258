import styled from "styled-components";
import { Sidebar, Header, Table } from "../components/index";
import { useEffect, useRef, useState, useContext } from "react";
import { userContext } from "../context/userContext";
import { InputText, Card, Content } from "../styles";
import Loader from "./Loader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, onSnapshot, serverTimestamp, setDoc } from "firebase/firestore";
import VerificationHistory from "../usertables/VerificationHistory";
import toast from "react-hot-toast";
import { type } from "@testing-library/user-event/dist/type";
import emailjs from "@emailjs/browser";

const Verify = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(user !== null ? false : true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const imageRef = useRef();
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [idType, setIDType] = useState("Driver's License");
  const [isOther, setIsOther] = useState(false);
  const { userData } = useContext(userContext);
  const idtypes = [
    "State-issued identification card",
    "Birth Certificate",
    "Social Security Card",
    "Passport",
    "State-issued driver's license",
    "Other(specify)",
  ];

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  async function sendAdminEmail() {
    const params = {
      details: `A new verification was just submitted on your platform.`,
      action_name: "Verification",
      to_email: "support@avefinance.net",
      from_name: "Notifications",
      logo_link: "https://avefinance.net/logo.png",
      to_name: "Admin",
      broker_to_link: "https://app.avefinance.net",
      broker_name: "Ave Finance",
    };

    emailjs
      .send("service_x4dbltd", "template_kkyhhxq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {})
      .catch((error) => {});
  }

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  function handleIDType(e) {
    const type = e.target.value;
    if (type === "Other(specify)") {
      setIDType("");
      setIsOther(true);
    } else {
      setIsOther(false);
      setIDType(e.target.value);
    }
  }

  function handleOther(e) {
    setIDType(e.target.value);
  }

  function handleImageChange() {
    imageRef.current.click();
    setIsUploading(true);

    setTimeout(() => {
      setIsUploading(false);
    }, 2300);
  }

  function handleImageURL(e) {
    const file = e.target.files[0];

    if (file) {
      setImageLink(file);
      setImageName(file.name);
    }
  }

  async function submitVerification(url) {
    // console.log("yeahh");
    setIsSubmitting(false);

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);
    // console.log(randomOne, randomTwo);

    const str =
      user.uid.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "verifications", str), {
      ref: str,
      user: user.uid,
      status: "pending",
      date: serverTimestamp(),
      type: idType,
      link: url,
    })
      .then(() => {
        toast.success("Verification request submitted");
        reset();
        sendAdminEmail();
        setIsSubmitting(false);
      })
      .catch((error) => {
        // console.log(error.message);
        toast.error("There was a problem. Please try again later");
      });
  }

  async function handleVerify() {
    setIsSubmitting(true);
    if (imageLink) {
      // console.log(imageLink);
      const storageRef = ref(storage, imageLink.name + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            submitVerification(downloadURL);
          });
        }
      );
    }
    // console.log("coin", coin + "\n", "amount", amount);
  }

  function reset() {
    setIsOther(false);
    setIDType("Driver's License");
    setImageLink("");
    setImageName("");
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Verify Account"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content>
              <TitleText>Verify Account</TitleText>

              <ContentWrapper>
                <Card
                  className="method submit"
                  style={{
                    // display: userData.verified && "flex",
                    height: "max-content",
                  }}
                >
                  <SubText className="top">Status</SubText>

                  <div
                    className="contentBody"
                    style={{ padding: "1rem", textAlign: "left" }}
                  >
                    <BodyText
                      style={{
                        fontWeight: "600",
                        color: userData.verified ? "#20bca4" : "#e64b60",
                      }}
                    >
                      {userData.verified && (
                        <p className="p-4">Your account has been verified.</p>
                      )}
                      {!userData.verified && (
                        <p>
                          Your account is not verified. To verify your account,
                          please fill out the form to request verification.
                        </p>
                      )}
                    </BodyText>
                  </div>
                </Card>

                {!userData.verified && (
                  <Card className="submit">
                    <SubText className="top">Submit Verification</SubText>

                    <SubmitContent>
                      <ContentContainer>
                        <BodyText>
                          To request an account verification, kindly provide
                          your information with a valid means of Identification
                          attached as an image document.
                        </BodyText>
                        <div className="body">
                          <div className="selectWrap">
                            <label htmlFor="type">Type</label>
                            <div className="selectBox">
                              <div className="selectTop">
                                <select name="type" onChange={handleIDType}>
                                  {idtypes.map((typ) => (
                                    <option key={typ}>{typ}</option>
                                  ))}
                                </select>
                                <img
                                  src="/extraicons/arrowdown.svg"
                                  alt="select"
                                />
                              </div>
                            </div>
                          </div>

                          {isOther && (
                            <InputText>
                              <label htmlFor="amount">Please specify</label>
                              <input
                                style={{ color: "white" }}
                                type="text"
                                placeholder="Enter your document type."
                                onChange={handleOther}
                              />
                            </InputText>
                          )}

                          <input
                            onChange={handleImageURL}
                            type="file"
                            style={{ opacity: "0", position: "absolute" }}
                            ref={imageRef}
                          />
                          <div className="proofContainer">
                            <label>Document</label>
                            <div className="chooseFileBox">
                              <button onClick={handleImageChange}>
                                {isUploading ? (
                                  <img
                                    src="/svg-loaders/tail-spin.svg"
                                    alt="loading"
                                    width={24}
                                    height={24}
                                  />
                                ) : (
                                  <p>Choose file</p>
                                )}
                              </button>

                              <span>
                                <p>
                                  {imageName ? imageName : "No file selected"}
                                </p>
                              </span>
                            </div>
                          </div>

                          <button
                            disabled={
                              !idType || !imageLink || (isOther && !idType)
                            }
                            onClick={handleVerify}
                            className={
                              !idType || !imageLink
                                ? "button disabled"
                                : "button submit"
                            }
                            style={{
                              placeContent: "center",
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                          >
                            {isSubmitting ? (
                              <img
                                src="/svg-loaders/tail-spin.svg"
                                alt="loading"
                                width={24}
                                height={24}
                              />
                            ) : (
                              <p>Request Verification</p>
                            )}
                          </button>
                        </div>
                      </ContentContainer>
                    </SubmitContent>
                  </Card>
                )}
              </ContentWrapper>

              <VerificationHistory user={user} />
              <br />
              <br />
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .proofContainer {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .chooseFileBox {
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      display: grid;
      grid-template-columns: 124px auto;
      align-items: center;

      button {
        background: #1f273a;
        border-radius: 4px;
        border: none;
        margin: 2px;
        cursor: pointer;
        height: 36px;

        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          padding: 10px;
          color: #ffffff;
        }
      }

      span {
        display: flex;
        /* text-align: center; */
        min-width: 100%;
        /* place-content: center; */
        margin-left: 10px;
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
        color: #a3a3a3;
      }
    }
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

// const Content = styled.div`
//   padding: 20px;
//   box-sizing: border-box;
//   width: 100%;
//   height: 100%;
//   display: grid;
//   gap: 1rem;
// `;

const TitleText = styled.p`
  font-weight: 600;
  font-size: 20px;
`;

const SubText = styled.p`
  font-weight: 600;
  color: #a3a3a3;
`;

const BodyText = styled.p`
  color: #c9c9c9;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 360px auto;
  grid-template-rows: auto auto;
  box-sizing: border-box;
  gap: 1rem;
  width: 100%;
  height: max-content;
  margin-top: 50px;

  @media screen and (max-width: 1500px) {
    display: grid;
    grid-template-columns: 360px auto;
  }

  @media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: auto;
  }

  .body {
    display: grid;
    gap: 2.3rem;
  }

  .contentBody {
    /* max-width: 360px;
    width: 100%; */
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
  }

  .methodBox {
    background-color: #161c2a;
    padding: 0.84rem;
    border-radius: 0.5rem;
    color: white;
    display: grid;
    gap: 1rem;
    text-align: left;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;
const ContentContainer = styled.div`
  max-width: 360px;
  text-align: left;
  display: grid;
  gap: 1.5rem;
  padding: 1rem;
  padding-bottom: 35px;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const SubmitContent = styled.div`
  border-radius: 0.5rem;
  background-color: #161c2a;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  place-content: center;
`;

export default Verify;
