import styled from "styled-components";
import { Sidebar, Header } from "../components/index";
import { useEffect, useState } from "react";
import { BlueButton, Content, Search, TitleText } from "../styles";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import { useMediaQuery } from "@mui/material";
import toast from "react-hot-toast";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useRef } from "react";
import AddExpertModal from "../modals/AddExpertModal";
import EditExpertModal from "../modals/EditExpertModal";

const CopyExperts = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const navigate = useNavigate();
  const { userData } = useContext(userContext);
  const [defaultExperts, setDefaultExperts] = useState([]);
  const [searchExperts, setSearchExperts] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isUnSubscribing, setIsUnSubscribing] = useState(false);
  //   const [search, setSearch] = useState('')
  const searchRef = useRef();
  const [addExpert, setAddExpert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editExpert, setEditExpert] = useState(false);

  const [expert, setExpert] = useState({});

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  // useEffect(() => {

  //  const unsub = onSnapshot(doc(db, "admin", "experts"), (doc) => {
  //       setDefaultExperts(doc.data())
  //   });

  //   unsub()
  // }, [])

  // async function addToDB() {
  //   await setDoc(doc(db, "admin", "plans"),

  //   {
  //     Starter: {
  //       duration: 3,
  //       maximum: 19500,
  //       minimum: 11000,
  //       name: "Starter",
  //       roi: 200,
  //       details: [
  //       {
  //       name: "DURATION",
  //       value: "3 DAYS"
  //       },
  //       {
  //       name: "ROI",
  //       value: "200%"
  //       },
  //       {
  //       name: "MAXIMUM",
  //       value: "19500"
  //       },
  //       ]
  //     },
  //     Lite: {
  //       duration: 3,
  //       maximum: 49500,
  //       minimum: 20000,
  //       name: "Lite",
  //       roi: 300,
  //       details: [
  //       {
  //       name: "DURATION",
  //       value: "3 DAYS"
  //       },
  //       {
  //       name: "ROI",
  //       value: "300%"
  //       },
  //       {
  //       name: "MAXIMUM",
  //       value: "49500"
  //       },
  //       ]
  //     },
  //     Classic: {
  //       duration: 3,
  //       maximum: 99500,
  //       minimum: 50000,
  //       name: "Classic",
  //       roi: 350,
  //       details: [
  //       {
  //       name: "DURATION",
  //       value: "3 DAYS"
  //       },
  //       {
  //       name: "ROI",
  //       value: "350%"
  //       },
  //       {
  //       name: "MAXIMUM",
  //       value: "99500"
  //       },
  //       ]
  //     },
  //     Pro: {
  //       duration: 3,
  //       maximum: 1000000,
  //       minimum: 100000,
  //       name: "Pro",
  //       roi: 403,
  //       details: [
  //       {
  //       name: "DURATION",
  //       value: "3 DAYS"
  //       },
  //       {
  //       name: "ROI",
  //       value: "403%"
  //       },
  //       {
  //       name: "MAXIMUM",
  //       value: "1000000"
  //       },
  //       ]
  //     }
  //   }

  //   ).then(() => {
  //     console.log("done");
  //   });
  // }

  async function fetchDocs() {
    const docRef = doc(db, "admin", "experts");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      setDefaultExperts(docSnap.data());
    } else {
      console.log("No such document!");
    }
  }

  useEffect(() => {
    async function fetchDocs() {
      const docRef = doc(db, "admin", "experts");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        setDefaultExperts(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }

    fetchDocs();
  }, []);

  // admin/plans admin/experts

  useEffect(() => {
    if (!searchRef.current?.value) {
      setSearchExperts(false);
    }
  }, [searchRef.current?.value]);

  function handleExperts(e) {
    const value = e.target.value;
    if (!value) {
      setSearchExperts(false);
    }

    let filter = [];

    Object.values(defaultExperts).forEach((expert) => {
      if (
        String(expert.name).toLowerCase().includes(String(value).toLowerCase())
      ) {
        filter.push(expert);
      } else {
        setSearchExperts(false);
      }
    });

    setSearchExperts(filter);
  }

  function handleSubscribe(name, subscriberList) {
    setIsSubscribing(name);
    // setIsSubscribing(true)

    let list = [];

    if (subscriberList) {
      subscriberList.forEach((sub) => {
        list.push(sub);
      });
    }

    list.push(user.uid);

    setSubscribe(name, list);
  }

  async function setSubscribe(name, list) {
    const ref = doc(db, "admin", "experts");
    const index = `${name}.subscriberList`;

    await updateDoc(ref, {
      [index]: list,
    })
      .then(() => {
        toast.success("Expert successfully copied");
        setIsSubscribing(false);
        fetchDocs();
      })
      .catch((error) => {
        toast.error("Please try again later");
        setIsSubscribing(false);
      });
  }

  function handleUnsubscribe(name, subscriberList) {
    setIsUnSubscribing(name);

    const list = subscriberList.filter((sub) => sub !== user.uid);

    setTimeout(() => {}, 600);

    setUnsubscribe(name, list);
  }

  async function setUnsubscribe(name, list) {
    const ref = doc(db, "admin", "experts");
    const index = `${name}.subscriberList`;

    await updateDoc(ref, {
      [index]: list,
    })
      .then(() => {
        toast.success("Copying canceled successfully");
        setIsUnSubscribing(false);
        fetchDocs();
      })
      .catch((error) => {
        toast.error("Please try again later");
        setIsUnSubscribing(false);
      });
  }

  async function handleEditExpert(expert) {
    setExpert(expert);

    setEditExpert(true);
  }

  async function handleAddExpert() {
    setAddExpert(true);
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          {addExpert && <AddExpertModal open={{ addExpert, setAddExpert }} />}

          {editExpert && (
            <EditExpertModal
              open={{ editExpert, setEditExpert }}
              user={userData}
              expert={expert}
            />
          )}
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Copy Experts"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Copy Expert Trades</TitleText>
              <ContentWrapper>
                <div className="flex space-x-4">
                  {userData.admin && (
                    <button
                      className="actionButton"
                      onClick={() => handleAddExpert()}
                      // onClick={() => addToDB()}
                      // () => handleAddExpert()
                    >
                      <p className="actionText w-[100%]">Add expert</p>
                    </button>
                  )}

                  <Search className="searchContainer">
                    <input
                      type="text"
                      placeholder="Search for an expert"
                      name="search"
                      onChange={handleExperts}
                      ref={searchRef}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </Search>
                </div>

                {/* <BlueButton onClick={() => addToDB()}>ADD DB</BlueButton> */}

                <div
                  className="w-full flex gap-[16px] w-full"
                  style={{
                    flexWrap: "wrap",
                    justifyContent: mobile && "center",
                  }}
                >
                  {searchExperts
                    ? searchExperts.map((expert) => (
                        <div key={expert.name}>
                          <div
                            className="w-full md:max-w-[360px] py-[32px] px-[16px] bg-[#131824]"
                            style={{ borderRadius: "10px" }}
                          >
                            <div className="grid gap-[24px]">
                              <figure>
                                <img
                                  src={expert.picture}
                                  alt="expert"
                                  className="w-[120px] h-[120px] rounded-full"
                                  style={{ objectFit: "cover" }}
                                />
                              </figure>

                              <div className="flex gap-2 items-center">
                                <p className="font-bold text-[28px]">
                                  {expert.name}
                                </p>
                                {expert.subscriberList?.includes(user.uid) && (
                                  <img
                                    src="/extraicons/checkmark.svg"
                                    alt="following"
                                  />
                                )}
                              </div>

                              <div
                                className="flex max-w-[max-content] gap-[8px] mt-[8px]"
                                style={{ flexWrap: "wrap" }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    win rate
                                  </p>
                                  <p className="text-[16px]">
                                    {expert.winRate}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    profit share
                                  </p>
                                  <p className="text-[16px]">
                                    {expert.profitShare}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    wins
                                  </p>
                                  <p className="text-[16px]">{expert.wins}</p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    losses
                                  </p>
                                  <p className="text-[16px]">{expert.losses}</p>
                                </div>
                              </div>

                              <div className="mt-[58px]">
                                {expert.subscriberList?.includes(user.uid) ? (
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={() =>
                                        handleUnsubscribe(
                                          expert.name,
                                          expert.subscriberList
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#E64B60",
                                        color: "white",
                                        textTransform: "uppercase",
                                        height: "44px",
                                        display: "grid",
                                        placeItems: "center",
                                        fontWeight: "600",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {isUnSubscribing === expert.name ? (
                                        <img
                                          src="/svg-loaders/tail-spin.svg"
                                          alt="loading"
                                          width={24}
                                          height={24}
                                        />
                                      ) : (
                                        "Cancel"
                                      )}
                                    </button>

                                    {userData.admin && (
                                      <button
                                        onClick={() => handleEditExpert(expert)}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#E64B60",
                                          color: "white",
                                          textTransform: "uppercase",
                                          height: "44px",
                                          display: "grid",
                                          placeItems: "center",
                                          fontWeight: "600",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {isUnSubscribing === expert.name ? (
                                          <img
                                            src="/svg-loaders/tail-spin.svg"
                                            alt="loading"
                                            width={24}
                                            height={24}
                                          />
                                        ) : (
                                          "Edit"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={() =>
                                        handleSubscribe(
                                          expert.name,
                                          expert.subscriberList
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#1199fa",
                                        color: "white",
                                        textTransform: "uppercase",
                                        height: "44px",
                                        display: "grid",
                                        placeItems: "center",
                                        fontWeight: "600",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {isSubscribing === expert.name ? (
                                        <img
                                          src="/svg-loaders/tail-spin.svg"
                                          alt="loading"
                                          width={24}
                                          height={24}
                                        />
                                      ) : (
                                        "Copy"
                                      )}
                                    </button>

                                    {userData.admin && (
                                      <button
                                        onClick={() => handleEditExpert(expert)}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#E64B60",
                                          color: "white",
                                          textTransform: "uppercase",
                                          height: "44px",
                                          display: "grid",
                                          placeItems: "center",
                                          fontWeight: "600",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {isUnSubscribing === expert.name ? (
                                          <img
                                            src="/svg-loaders/tail-spin.svg"
                                            alt="loading"
                                            width={24}
                                            height={24}
                                          />
                                        ) : (
                                          "Edit"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* {!expert.subscriberList?.includes(user.uid) &&   <div className="w-full md:max-w-[360px] py-[32px] px-[16px] bg-[#131824]" style={{borderRadius: "10px"}}>
                             <div className="grid gap-[24px]">
                                 <figure>
                                     <img src={expert.picture} alt="expert" className="w-[120px] h-[120px] rounded-full" style={{objectFit: "cover"}}/>
                                 </figure>
         
                                 <div className="flex gap-2 items-center">
                                 <p className="font-bold text-[28px]">{expert.name}</p>
                                 {expert.subscriberList?.includes(user.uid) && <img src="/extraicons/checkmark.svg" alt="following" />}
                                 </div>
         
                                 <div className="flex max-w-[max-content] gap-[8px] mt-[8px]" style={{flexWrap: "wrap"}}>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">win rate</p>
                                         <p className="text-[16px]">{expert.winRate}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">profit share</p>
                                         <p className="text-[16px]">{expert.profitShare}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">wins</p>
                                         <p className="text-[16px]">{expert.wins}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">losses</p>
                                         <p className="text-[16px]">{expert.losses}</p>
                                     </div>
                                 </div>
         
         
                                 <div className="mt-[58px]">
                                     {expert.subscriberList?.includes(user.uid) ? 
                                 
                                      <button onClick={() => handleUnsubscribe(expert.name, expert.subscriberList)} style={{width: "100%", backgroundColor: "#E64B60", color: "white", textTransform: "uppercase", height: "44px", display: "grid", placeItems: "center", fontWeight: "600", borderRadius: "8px"}}>{ isUnSubscribing ? (<img
                                         src="/svg-loaders/tail-spin.svg"
                                         alt="loading"
                                         width={24}
                                         height={24}
                                       />) : "Cancel" }</button> 
                                    
                                      
                                      : <button onClick={() => handleSubscribe(expert.name, expert.subscriberList)} style={{width: "100%", backgroundColor: "#1199fa", color: "white", textTransform: "uppercase", height: "44px", display: "grid", placeItems: "center", fontWeight: "600", borderRadius: "8px"}}>
                                     { isSubscribing ? (<img
                                         src="/svg-loaders/tail-spin.svg"
                                         alt="loading"
                                         width={24}
                                         height={24}
                                       />) : "Copy" }</button>}
                                 </div>
         
         
                             </div>
                         </div>} */}
                        </div>
                      ))
                    : Object.values(defaultExperts).map((expert) => (
                        <div key={expert.name}>
                          <div
                            className="w-full md:max-w-[360px] py-[32px] px-[16px] bg-[#131824]"
                            style={{ borderRadius: "10px" }}
                          >
                            <div className="grid gap-[24px]">
                              <figure>
                                <img
                                  src={expert.picture}
                                  alt="expert"
                                  className="w-[120px] h-[120px] rounded-full"
                                  style={{ objectFit: "cover" }}
                                />
                              </figure>

                              <div className="flex gap-2 items-center">
                                <p className="font-bold text-[28px]">
                                  {expert.name}
                                </p>
                                {expert.subscriberList?.includes(user.uid) && (
                                  <img
                                    src="/extraicons/checkmark.svg"
                                    alt="following"
                                  />
                                )}
                              </div>

                              <div
                                className="flex max-w-[max-content] gap-[8px] mt-[8px]"
                                style={{ flexWrap: "wrap" }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    win rate
                                  </p>
                                  <p className="text-[16px]">
                                    {expert.winRate}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    profit share
                                  </p>
                                  <p className="text-[16px]">
                                    {expert.profitShare}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    wins
                                  </p>
                                  <p className="text-[16px]">{expert.wins}</p>
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #1F273A",
                                    padding: "12px 16px",
                                    boxSizing: "border-box",
                                    maxWidth: "max-content",
                                    borderRadius: "8px",
                                    backgroundColor: "#151B28",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p className="text-[12px] uppercase text-[#1199fa]">
                                    losses
                                  </p>
                                  <p className="text-[16px]">{expert.losses}</p>
                                </div>
                              </div>

                              <div className="mt-[58px]">
                                {expert.subscriberList?.includes(user.uid) ? (
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={() =>
                                        handleUnsubscribe(
                                          expert.name,
                                          expert.subscriberList
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#E64B60",
                                        color: "white",
                                        textTransform: "uppercase",
                                        height: "44px",
                                        display: "grid",
                                        placeItems: "center",
                                        fontWeight: "600",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {isUnSubscribing === expert.name ? (
                                        <img
                                          src="/svg-loaders/tail-spin.svg"
                                          alt="loading"
                                          width={24}
                                          height={24}
                                        />
                                      ) : (
                                        "Cancel"
                                      )}
                                    </button>

                                    {userData.admin && (
                                      <button
                                        onClick={() => handleEditExpert(expert)}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#E64B60",
                                          color: "white",
                                          textTransform: "uppercase",
                                          height: "44px",
                                          display: "grid",
                                          placeItems: "center",
                                          fontWeight: "600",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {isUnSubscribing === expert.name ? (
                                          <img
                                            src="/svg-loaders/tail-spin.svg"
                                            alt="loading"
                                            width={24}
                                            height={24}
                                          />
                                        ) : (
                                          "Edit"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={() =>
                                        handleSubscribe(
                                          expert.name,
                                          expert.subscriberList
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#1199fa",
                                        color: "white",
                                        textTransform: "uppercase",
                                        height: "44px",
                                        display: "grid",
                                        placeItems: "center",
                                        fontWeight: "600",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {isSubscribing === expert.name ? (
                                        <img
                                          src="/svg-loaders/tail-spin.svg"
                                          alt="loading"
                                          width={24}
                                          height={24}
                                        />
                                      ) : (
                                        "Copy"
                                      )}
                                    </button>

                                    {userData.admin && (
                                      <button
                                        onClick={() => handleEditExpert(expert)}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#E64B60",
                                          color: "white",
                                          textTransform: "uppercase",
                                          height: "44px",
                                          display: "grid",
                                          placeItems: "center",
                                          fontWeight: "600",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {isUnSubscribing === expert.name ? (
                                          <img
                                            src="/svg-loaders/tail-spin.svg"
                                            alt="loading"
                                            width={24}
                                            height={24}
                                          />
                                        ) : (
                                          "Edit"
                                        )}
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* {!expert.subscriberList?.includes(user.uid) &&   <div className="w-full md:max-w-[360px] py-[32px] px-[16px] bg-[#131824]" style={{borderRadius: "10px"}}>
                             <div className="grid gap-[24px]">
                                 <figure>
                                     <img src={expert.picture} alt="expert" className="w-[120px] h-[120px] rounded-full" style={{objectFit: "cover"}}/>
                                 </figure>
         
                                 <div className="flex gap-2 items-center">
                                 <p className="font-bold text-[28px]">{expert.name}</p>
                                 {expert.subscriberList?.includes(user.uid) && <img src="/extraicons/checkmark.svg" alt="following" />}
                                 </div>
         
                                 <div className="flex max-w-[max-content] gap-[8px] mt-[8px]" style={{flexWrap: "wrap"}}>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">win rate</p>
                                         <p className="text-[16px]">{expert.winRate}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">profit share</p>
                                         <p className="text-[16px]">{expert.profitShare}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">wins</p>
                                         <p className="text-[16px]">{expert.wins}</p>
                                     </div>
                                     <div style={{border: "1px solid #1F273A", padding: "12px 16px", boxSizing: 'border-box', maxWidth: "max-content", borderRadius: "8px", backgroundColor: "#151B28", fontWeight: "500"}}>
                                         <p className="text-[12px] uppercase text-[#1199fa]">losses</p>
                                         <p className="text-[16px]">{expert.losses}</p>
                                     </div>
                                 </div>
         
         
                                 <div className="mt-[58px]">
                                     {expert.subscriberList?.includes(user.uid) ? 
                                 
                                      <button onClick={() => handleUnsubscribe(expert.name, expert.subscriberList)} style={{width: "100%", backgroundColor: "#E64B60", color: "white", textTransform: "uppercase", height: "44px", display: "grid", placeItems: "center", fontWeight: "600", borderRadius: "8px"}}>{ isUnSubscribing ? (<img
                                         src="/svg-loaders/tail-spin.svg"
                                         alt="loading"
                                         width={24}
                                         height={24}
                                       />) : "Cancel" }</button> 
                                    
                                      
                                      : <button onClick={() => handleSubscribe(expert.name, expert.subscriberList)} style={{width: "100%", backgroundColor: "#1199fa", color: "white", textTransform: "uppercase", height: "44px", display: "grid", placeItems: "center", fontWeight: "600", borderRadius: "8px"}}>
                                     { isSubscribing ? (<img
                                         src="/svg-loaders/tail-spin.svg"
                                         alt="loading"
                                         width={24}
                                         height={24}
                                       />) : "Copy" }</button>}
                                 </div>
         
         
                             </div>
                         </div>} */}
                        </div>
                      ))}
                </div>
              </ContentWrapper>
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Main = styled.div`
  width: 100vw;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100vh;

  .content {
    max-width: 100%;
    overflow-x: auto;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 2.5rem;
  margin-top: 50px;
  width: 100%;
  padding-bottom: 2rem;

  .tables {
    width: 100%;
    overflow-x: auto;
  }

  .switches {
    max-width: 280px;
  }

  .cardContainer {
    max-width: 280px;
  }

  .searchContainer {
    max-width: 280px;
  }

  @media screen and (max-width: 800px) {
    .cardContainer {
      max-width: 100%;
    }

    .searchContainer {
      max-width: 100%;
    }
  }

  .card {
    box-sizing: border-box;
    max-width: 100%;
  }

  .bodyText {
    font-size: 18px;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
  }
`;

export default CopyExperts;
